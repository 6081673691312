import {useTranslation} from "react-i18next";
import {SizedBox} from "../../components/base/SizedBox/SizedBox";
import DashboardLayout from "../../components/layouts/DashboardLayout/DashboardLayout";
import styles from "./LivePage.module.scss";
import {AthleteSelector} from "../../components/paddlemate/AthleteSelector/AthleteSelector";
import {useContext, useEffect, useState} from "react";
import {LiveApi} from "../../utils/liveApi";
import {Moment} from "moment";
import {TrainingSport} from "../../typings/TrainingSport";
import moment from "moment/moment";
import {max} from "../../utils/arrayUtils";
import {Spinner} from "../../components/base/Spinner/Spinner";
import {Card} from "reactstrap";
import {LiveBoat} from "../../components/paddlemate/LiveBoat/LiveBoat";
import {LiveCharts} from "../../components/paddlemate/LiveCharts/LiveCharts";
import {LiveMap} from "../../components/paddlemate/LiveMap/LiveMap";
import Icon from "@mdi/react";
import {mdiChartBar, mdiMap} from "@mdi/js";
import AthleteFilterContext from "../../contexts/AthleteFilterContext";


export interface ILiveAthleteStat {
    hr: number | null,
    force: number | null,
}

export interface ILiveBoatStat {
    speed: number | null,
    strokeRate: number | null,
    location: { lat: number, lng: number } | null;
}

export interface ILiveBoat {
    id: string;
    color: string;
    isActive: boolean;
    createdAt: Moment | null;
    creatorId: number;
    distance?: number;
    speed?: number;
    strokeRate?: number;
    distancePerStroke: number;
    location?: { lat: number, lng: number };
    sport?: TrainingSport,
    athletes: ILiveBoatAthlete[];
    stats?: ILiveBoatStat[];
    subscriptionExpired: boolean;
}

export interface ILiveBoatAthlete {
    id: number;
    name: string;
    color?: string;
    hr?: number;
    force?: number;
    maxForce?: number;
    forceSeriesLeft?: number[],
    forceSeriesRight?: number[],
    stats?: ILiveAthleteStat[];
}

const statsById: { [key: string]: ILiveBoatStat[] } = {};
const athleteStatsByLocalId: { [key: string]: ILiveAthleteStat[] } = {};
const statsSize = 15 * 60;

function getBoatColor() {
    return "#ffffff";
}

export const LivePage = () => {
    const {t} = useTranslation();
    const [selectedBoatId, setSelectedBoat] = useState<string>();
    const [boats, setBoats] = useState<ILiveBoat[]>([]);
    const [boatsLoaded, setBoatsLoaded] = useState(false);
    const [tabState, setTabState] = useState("");
    const [lastBoatSelectTime, setLastBoatSelectTime] = useState(0);
    const {athlete} = useContext(AthleteFilterContext);

    useEffect(() => {
        let timeOutRef: any;
        let stopped = false;
        const update = async () => {
            const liveDatas = await LiveApi.getAllData()
            const emptyAthleteStat: ILiveAthleteStat = {
                hr: null,
                force: null
            };
            const emptyBoatStat: ILiveBoatStat = {
                speed: null,
                strokeRate: null,
                location: null
            };
            for (const key of Object.keys(statsById)) {
                if (statsById[key].length > statsSize)
                    statsById[key].shift();
                statsById[key].push(emptyBoatStat);
            }
            for (const key of Object.keys(athleteStatsByLocalId)) {
                if (athleteStatsByLocalId[key].length > statsSize)
                    athleteStatsByLocalId[key].shift();
                athleteStatsByLocalId[key].push(emptyAthleteStat);
            }
            let newBoats = liveDatas.map<ILiveBoat>(ld => {
                const createdAt = (ld.createdAt && moment.utc(ld.createdAt)) || null;
                const isActive = (createdAt && createdAt.diff(moment(), "hours") > -1)!!;
                //TODO refactor: move out of map()
                if (!statsById[ld.id]) {
                    statsById[ld.id] = Array.from({length: statsSize}, () => emptyBoatStat);
                }
                statsById[ld.id][statsById[ld.id].length - 1] = {
                    speed: ld.speedKmph,
                    strokeRate: ld.strokeRateSpm,
                    location: ld.coordinate
                } as ILiveBoatStat;
                let dps = (ld.speedKmph && ld.speedKmph * 1000 / ((ld.strokeRateSpm ?? 0) * 60)) || 0;
                if (Number.isNaN(dps) || !Number.isFinite(dps) || dps === undefined) {
                    dps = 0;
                }
                return {
                    id: ld.id,
                    isActive,
                    color: getBoatColor(),
                    createdAt: createdAt,
                    creatorId: ld.creatorId,
                    distance: ld.distanceKm,
                    speed: ld.speedKmph,
                    strokeRate: ld.strokeRateSpm,
                    distancePerStroke: dps,
                    location: ld.coordinate,
                    sport: ld.sport,
                    subscriptionExpired: ld.subscriptionExpired,
                    athletes: ld.seats.map((seat, i) => {
                        const localId = `${ld.id}-${i}`;
                        //TODO refactor: move out of from map
                        if (!athleteStatsByLocalId[localId]) {
                            athleteStatsByLocalId[localId] = Array.from({length: statsSize}, () => emptyAthleteStat);
                        }
                        athleteStatsByLocalId[localId][athleteStatsByLocalId[localId].length - 1] = {
                            hr: seat.hrBpm,
                            force: seat.avgForceN
                        };
                        return ({
                            id: seat.athlete.id,
                            name: seat.athlete.name,
                            color: "#ffffff",
                            hr: seat.hrBpm,
                            force: seat.avgForceN,
                            maxForce: max(athleteStatsByLocalId[localId].slice(-30).map(x => x.force || 0)),
                            forceSeriesLeft: seat.leftForcesN,
                            forceSeriesRight: seat.rightForcesN,
                            stats: athleteStatsByLocalId[localId],
                        });
                    }),
                    stats: statsById[ld.id],
                };
            });
            if (athlete) {
                newBoats = newBoats.filter(x => x.athletes.some(y => y.id === athlete.id));
            }
            setBoats(newBoats.sort((a, b) => Number(b.isActive) - Number(a.isActive)));
            setBoatsLoaded(true);
        };

        const startTimeout = () => {
            if (stopped) return;
            timeOutRef = setTimeout(() => {
                update().catch(console.log)
                    .finally(startTimeout);
            }, 750);
        }
        startTimeout();
        return () => {
            if (timeOutRef)
                clearTimeout(timeOutRef);
            stopped = true;
        };
    }, [athlete]);

    useEffect(() => {
        if (athlete)
            setBoats(b => b.filter(x => x.athletes.some(y => y.id === athlete.id)));
        else
            setBoats([]);
    }, [athlete]);

    const toggleTab = (tab: string) => {
        setTabState(x => x === tab ? "" : tab);
    }

    const selectedBoat = boats.find(x => x.id === selectedBoatId);
    const activeBoats = boats.filter(x => x.isActive);
    const handleBoatClick = (id: string) => {
        setSelectedBoat(id);
        setLastBoatSelectTime(Date.now())
    };

    function getMapPath() {
        let mapPath = selectedBoatId !== undefined && statsById[selectedBoatId] ?
            statsById[selectedBoatId]
                .map(x => x.location)
                .filter(x => x) : [];
        //return mapPath;
        if (mapPath.length > 1) { // take locations that are close to the previous
            const filtered: any[] = [];

            function haversineDistanceKm(p1: any, p2: any) {
                const R = 6371; // Radius of the Earth in km
                const rlat1 = p1.lat * (Math.PI / 180);
                const rlat2 = p2.lat * (Math.PI / 180);
                const difflat = rlat2 - rlat1;
                const difflon = (p1.lng - p2.lng) * (Math.PI / 180);
                return 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2)
                    + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
            }

            let lastElement = mapPath[mapPath.length - 1];
            for (let i = mapPath.length - 1; i >= 0; i--) {
                const distanceThresholdM = 2000;
                if (haversineDistanceKm(mapPath[i], lastElement) * 1000 < distanceThresholdM) {
                    filtered.push(mapPath[i])
                    lastElement = mapPath[i];
                } else break;
            }

            return filtered;
        } else {
            return mapPath;
        }
    }

    return <>
        <DashboardLayout scrollable={false}>
            <div className={styles.content}>
                <SizedBox height={8}/>
                <div className={styles.header}>
                    <div className={styles.nameText}>
                        Live
                    </div>
                    <SizedBox height={8}/>
                    <div style={{flex: 1}}></div>
                    <div className={"d-flex"}>
                        <AthleteSelector/>
                    </div>
                </div>
                <SizedBox height={16}/>
                {!boatsLoaded &&
                    <div className={styles.loading}>
                        <Spinner/>
                    </div>
                }
                {!(boatsLoaded && boats.length === 0) &&
                    <div className={styles.container}>
                        <div className={styles.height100}>
                            <div className={`${styles.boats}`} style={{
                                ...(tabState !== "" && {flex: 1, marginBottom: "2em"}),
                            }}>
                                {boats.sort((a, b) =>
                                    b.isActive as any as number - (a.isActive as any as number)).map((x, i) =>
                                    <LiveBoat key={i}
                                              boat={x}
                                              selected={x.id === selectedBoatId}
                                              onClick={() => handleBoatClick(x.id)}/>
                                )}
                            </div>
                            {tabState !== "" &&
                                <Card className={styles.bottomPanel}>
                                    {tabState === "charts" &&
                                        <LiveCharts boat={selectedBoat}/>
                                    }
                                    {tabState === "map" &&
                                        <LiveMap boats={activeBoats}
                                                 path={getMapPath()}
                                                 selectedBoat={selectedBoat}
                                                 lastBoatSelectTime={lastBoatSelectTime}
                                        />
                                    }
                                </Card>
                            }
                        </div>
                    </div>
                }
                <div className={styles.bottomButtons}>
                    <a role="button" className={
                        `btn ${tabState === "charts" ? "btn-secondary" : `btn-outline-secondary ${styles.buttonNotActive}`}`}
                       onClick={() => toggleTab("charts")}>
                        <Icon path={mdiChartBar} size={1}/>
                        {t("charts")}
                    </a>
                    <a role="button" className={
                        `btn ${tabState === "map" ? "btn-secondary" : `btn-outline-secondary ${styles.buttonNotActive}`}`}
                       onClick={() => toggleTab("map")}>
                        <Icon path={mdiMap} size={1}/>
                        {t("map")}
                    </a>
                </div>
            </div>
        </DashboardLayout>
    </>
};
